export enum EmailType {
  Abandonment = "abandonment",
  PaidTrail = "paidTrail",
  Cancelletion = "Cancelletion",
  RankOnGoogleV1 = "RankOnGoogleV1",
  ParasiteSEO = "ParasiteSEO",
  JoinAffiliate = "JoinAffiliate",
  YearlyPlanDiscount = "YearlyPlanDiscount",
}

export interface SentEmailBody {
  type: EmailType;
  userId: string;
}

export enum RewriteType {
  Language = "Language",
}

export interface RewriteBody {
  type: RewriteType;
  html: string;
  language: string;
  postId: string;
  userId: string;
  metaTitle?: string;
  metaDescription?: string;
  slug?: string;
  faqs?: { question: string; answer: string }[];
}
