export const chunk = <T extends any[]>(input: T, size: number): T[] => {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
};

export function pick(object, keys) {
  return keys.reduce((obj, key) => {
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
}
export const capitalize = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const keyBy = (array, key) =>
  (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});

export function omit<T extends Record<string, any>>(
  obj: T,
  keys: string[]
): Partial<T> {
  const n = {};
  Object.keys(obj).forEach(function (key) {
    if (keys.indexOf(key) === -1) {
      n[key] = obj[key];
    }
  });
  return n;
}

export const findLast = (array, predicate) => {
  return array.findLast(predicate);
};

function isObjectLike(value) {
  return typeof value === "object" && value !== null;
}

const toString = Object.prototype.toString;

function getTag(value) {
  if (value == null) {
    return value === undefined ? "[object Undefined]" : "[object Null]";
  }
  return toString.call(value);
}
export function isPlainObject<T extends any>(value: T) {
  if (!isObjectLike(value) || getTag(value) != "[object Object]") {
    return false;
  }
  if (Object.getPrototypeOf(value) === null) {
    return true;
  }
  let proto = value;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }
  return Object.getPrototypeOf(value) === proto;
}

export function uniq(array) {
  return [...new Set(array)];
}

export function groupBy(arr, criteria) {
  return arr.reduce(function (obj, item) {
    // Check if the criteria is a function to run on the item or a property of it
    var key = typeof criteria === "function" ? criteria(item) : item[criteria];

    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }

    // Push the value to the object
    obj[key].push(item);

    // Return the object to the next item in the loop
    return obj;
  }, {});
}

export const uniqBy = function <T extends any[]>(arr: T, key: string): T {
  let seen = new Set();

  return arr.filter((it) => {
    let val = it[key];
    if (seen.has(val)) {
      return false;
    } else {
      seen.add(val);
      return true;
    }
  }) as T;
};

export function debounce(
  func: () => void,
  wait = 500,
  option: {
    leading?: boolean;
    trailing?: boolean;
  } = { leading: false, trailing: true }
) {
  let timeout = null;
  // Flag to skip the trailing if leading is true
  // and the debounced function isn't called again after the initial execution.
  let isCalledForLeading = false;

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    if (option.leading && timeout === null) {
      func.apply(null, args);
      isCalledForLeading = true;
    } else {
      isCalledForLeading = false;
    }

    timeout = setTimeout(() => {
      if (option.trailing && !isCalledForLeading) {
        func.apply(null, args);
      }
      timeout = null;
    }, wait);
  };
}

export function throttle(func: () => void, wait = 500) {
  // Track if we are waiting. Initially, we are not.
  let isWaiting = false;
  // Track arguments of last call
  let lastCallArgs = null;

  return function throttled(...args) {
    // If we are waiting,
    if (isWaiting) {
      // ...store arguments of last call
      lastCallArgs = args;
      return;
    }

    // If we are not waiting, execute 'func' with passed arguments
    func.apply(this, args);
    // Prevent future execution of 'func'
    isWaiting = true;

    // After wait time,
    setTimeout(() => {
      // ...allow execution of 'func'
      isWaiting = false;

      // If arguments of last call exists,
      if (lastCallArgs) {
        // ...execute function throttled and pass last call's arguments
        // to it. Since now we are not waiting, 'func' will be executed
        // and isWaiting will be reset to true.
        throttled.apply(this, lastCallArgs);
        // ...reset arguments of last call to null.
        lastCallArgs = null;
      }
    }, wait);
  };
}

export const isEmailValid = (value: string) => {
  if (!value) {
    return false;
  }
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return !!value.match(validRegex);
};

export const shuffuleArray = <T>(array: T[]): T[] => {
  let shuffled = array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
  return shuffled;
};
