const defaultLightGreen = "rgb(200, 250, 205)";
const defaultWhite = "rgb(255, 255, 255)";
const defaultTextGreen = "rgb(0, 171, 85)";

const defaultGrey = "rgb(249, 250, 251)";

const defaultBlue = "#40B5B5";

const colors = {
  background: {
    default: defaultWhite,
    paper: defaultWhite,
    mainLayout: "rgb(249, 250, 251)",
    secondary: "rgb(255, 171, 0)",
    secondaryFocus: "rgb(183, 110, 0)",
    softPrimaryFocus: "rgba(0, 171, 85, 0.32)",
    softPrimary: "rgba(0, 171, 85, 0.32)",
    other: "rgb(244, 246, 248)",
    blue: "rgb(0, 108, 156)",
    specialWhite: "#F8F8F8",
    specialBlue: "aliceblue",
    magic: "linear-gradient(-45deg, #ed1c94,#ffec17)",
    white: "white",
    black: "#111827",
    blue: defaultBlue,
    brand: defaultTextGreen,
  },

  text: {
    main: "#7b809a",
    secondary: "rgb(33, 43, 54)", // good
    softPrimary: "rgb(0, 123, 85)",
    focus: "rgb(0, 171, 85)",

    highlight: "rgb(0, 82, 73)",

    caption: "rgb(99, 115, 129)",
    listItem: "rgb(99, 115, 129)",
    black: "black",
    white: "white",
    blue: defaultBlue,
  },

  transparent: {
    main: "transparent",
  },

  overlay: {
    primary: "rgba(22, 28, 36, 0.64)",
  },

  borders: {
    primary: "rgb(99, 115, 129)",
    highlight: defaultTextGreen,
    secondary: "rgb(183, 110, 0)",
    white: "white",
    standard: "#d2d6da",
    special: "rgba(145, 158, 171, 0.24)",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "rgb(0, 171, 85)",
    focus: "#e91e63",
  },

  secondary: {
    main: defaultWhite,
    focus: "#8f93a9",
  },

  info: {
    main: "#1A73E8",
    focus: "#1662C4",
  },

  success: {
    main: "#4CAF50",
    focus: "#67bb6a",
  },

  warning: {
    main: "#fb8c00",
    focus: "#fc9d26",
  },

  error: {
    main: "#F44335",
    focus: "#f65f53",
  },

  light: {
    main: "#f0f2f5",
    focus: "#f0f2f5",
  },

  dark: {
    main: "rgb(33, 43, 54)",
    focus: "#2c3c58",
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "linear-gradient(135deg, rgb(91, 229, 132), rgb(0, 171, 85))",
      state: "#D81B60",
    },

    secondary: {
      main: "linear-gradient(135deg, rgb(183, 110, 0), rgb(255, 171, 0))",
      state: "#495361",
    },

    info: {
      main: "#49a3f1",
      state: "#1A73E8",
    },

    success: {
      main: "#66BB6A",
      state: "#43A047",
    },

    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },

    error: {
      main: "#EF5350",
      state: "#E53935",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      main: "#42424a",
      state: "#191919",
    },
  },

  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "#aecef7",
      text: "#095bc6",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  coloredShadows: {
    primary: "rgb(0, 82, 73)",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};

export default colors;
